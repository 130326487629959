<template>
    <v-container>
        <v-row>
            <v-col cols="12" md="6">
                <!-- Background image-->
                <v-row>
                    <v-col v-if="backgroundImageUrl" cols="12">
                        <v-sheet elevation="16" style="position: relative;">
                            <v-img contain :src="backgroundImageUrl">
                            </v-img>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center">
                        <v-file-input show-size counter @change="selectBackgroundImage" hint="Only jpg format"
                            accept="image/jpeg" label="Choose an image" v-model="inputFileImage"></v-file-input>

                        <v-btn color="primary" :disabled="!backgroundImageUrl" rounded small @click="deleteBackground">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <div v-if="backgroundInUpload">
                            <v-progress-linear v-model="uploadProgress" color="primary" reactive></v-progress-linear>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="6">
                <!-- Background video-->
                <v-row>
                    <v-col v-if="backgroundVideoUrl" cols="12">
                        <v-sheet elevation="16" style="position: relative;">
                            <div class="player-container">
                                <vue-core-video-player type="video/webm" :src="backgroundVideoUrl"></vue-core-video-player>
                            </div>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" class="d-flex align-center">
                        <v-file-input show-size accept="video/webm" counter hint="Only webm format" @change="selectVideo"
                            label="Choose a video" v-model="inputFileVideo"></v-file-input>

                        <v-btn color="primary" :disabled="!backgroundVideoUrl" rounded small @click="deleteVideo">
                            <v-icon dark>mdi-delete</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <div v-if="videoInUpload">
                            <v-progress-linear v-model="uploadProgress" color="primary" reactive>
                            </v-progress-linear>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import api from "@/api";
import agconf from '../../agconf'

export default {
    name: 'LinkBackgroundEditor',
    components: {
    },
    props: {
        handleError: {
            type: Function,
        },
        link: {
            type: Object,
            required: true
        },
        confirm: {
            type: Object,
            required: true
        }
    },
    computed: {

    },
    data() {
        return {
            // File cover upload
            backgroundInUpload: null,
            backgroundVideoInUpload: null,
            inputFileImage: null,
            inputFileVideo: null,
            uploadProgress: 0,
            videoInUpload: null,
            backgroundVideoUrl: '',
            backgroundImageUrl: '',

            rules: {
                required: (value) => !!value || "Required.",
            },
        };
    },
    async mounted() {
        try {
            const backgroundImageUrl = '/links/' + this.link.id + '/background?' + new Date().getTime();
            await api.checkFileExist(backgroundImageUrl);
            this.backgroundImageUrl = agconf.backendBaseUrl + backgroundImageUrl;
        } catch {
            this.backgroundImageUrl = null;
        }

        try {
            const result = await api.checkLinkVideoExist(this.link.id)
            this.backgroundVideoUrl = result
                ? agconf.backendBaseUrl + '/links/' + this.link.id + '/video?' + new Date().getTime()
                : null;
        } catch {
            this.backgroundVideoUrl = null;
        }
    },
    methods: {
        async deleteBackground() {
            try {
                await api.deleteLinkBackground(this.link.id);
                this.backgroundImageUrl = null;
                this.inputFileImage = null;
            } catch (error) {
                this.handleError(error, "There was an error while deleting the backgound.");
            }
        },
        async deleteVideo() {
            try {
                await api.deleteLinkVideoBackground(this.link.id)
                this.backgroundVideoUrl = null;
                this.inputFileVideo = null;
            } catch (error) {
                this.handleError(error, "There was an error while deleting the video.");
            }
        },
        selectBackgroundImage(file) {
            this.uploadProgress = 0;
            this.backgroundInUpload = file;
            this.uploadBackground();
        },
        selectVideo(file) {
            this.uploadProgress = 0;
            this.videoInUpload = file;
            this.uploadVideo();
        },
        async uploadBackground() {
            try {
                await api.uploadSingleFile(this.backgroundInUpload, "/links/" + this.link.id + "/background",
                    (event) => this.uploadProgress = Math.round((100 * event.loaded) / event.total));

                this.backgroundImageUrl = agconf.backendBaseUrl + '/links/' + this.link.id + '/background?' + new Date().getTime();
            } catch (error) {
                this.handleError(error, "There was an error during the upload of your amazing background.");
                this.backgroundImageUrl = null;
            } finally {
                this.uploadProgress = 0;
                this.backgroundInUpload = null;
            }
        },
        async uploadVideo() {
            try {
                await api.uploadSingleFile(this.videoInUpload, "/links/" + this.link.id + "/video",
                    (event) => this.uploadProgress = Math.round((100 * event.loaded) / event.total));

                this.backgroundVideoUrl = agconf.backendBaseUrl + '/links/' + this.link.id + '/video?' + new Date().getTime();
            } catch (error) {
                this.handleError(error, "There was an error during the upload of your amazing backgound video.");
                this.backgroundVideoUrl = null;
            } finally {
                this.uploadProgress = 0;
                this.backgroundInUpload = null;
            }
        }
    }
}
</script>
